.flex-container{
    display:flex;
    flex-direction: row;
    width:100%;
    /* justify- justify the children box along the main-axix */
    justify-content: flex-start;

    /* align- justify the children box along the croxx-axis */
    align-items: end;
    flex-wrap: wrap;
    

}
.item{
    background-color: burlywood;
    width:300px;
    padding:10px;
    margin:10px;
}