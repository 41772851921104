
$black: hsl(0, 0%, 0%);
$white: hsl(0, 0%, 100%);
$orange: hsl(26, 100%, 55%);
$pale-orange: hsl(25, 100%, 94%);
$very-dark-blue: hsl(220, 13%, 13%);
$dark-grayish-blue: hsl(219, 9%, 45%);
$grayish-blue: hsl(220, 14%, 75%);
$light-grayish-blue: hsl(223, 64%, 98%);
$transition: all 0.3s linear;
.checkout-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width:100%;
    align-items: flex-start;
    
}
.checkout-steps-container{
    box-shadow: 1px 2px 20px 0px lightgrey;
    display:flex;
    flex-direction: column;
    align-items: flex-start;
    width:70%;
    margin:1rem;
    justify-content: flex-start;
    justify-items: flex-start;



     
}
.checkout-step{
     border-bottom: 1px solid $grayish-blue;
     width:100%;
     display:flex;
     flex-direction: row;
     justify-content: space-between;
     padding:2rem;
     &-title{
        font-weight: bold;
        text-align: left;
     }
     &-info{
        display:flex;
        justify-content: space-between;
        width:70%;
     }
}

.placeorder-container{
    box-shadow: 1px 2px 20px 0px lightgrey;
   /* position: fixed;
    top: 1rem;
    right:1rem;*/
    display:flex;
    flex-direction: column;
    align-items: flex-start;
    width:30%;
    margin:1rem;
    padding:2rem;
    
    .term-privacy{
        margin:1rem 0rem;
        border-bottom: 1px solid $grayish-blue;
    }
    .summary-item-container{
        display:flex;
       
        
        width:100%;
        flex-direction: row;
        justify-content: space-between;
        
        
    }
    .summary-total-container{
        border-top:1px solid $grayish-blue;
        display:flex;
        
        width:100%;
        flex-direction: row;
        justify-content: space-between;
        font-size: large;
        margin-left:0rem;
        color:red
    }
    
    h5{
         
        align-self: flex-start;
    }
}