@import 'include-media';
/* SASS Variables */

$black: hsl(0, 0%, 0%);
$white: hsl(0, 0%, 100%);
$orange: hsl(26, 100%, 55%);
$pale-orange: hsl(25, 100%, 94%);
$very-dark-blue: hsl(220, 13%, 13%);
$dark-grayish-blue: hsl(219, 9%, 45%);
$grayish-blue: hsl(220, 14%, 75%);
$light-grayish-blue: hsl(223, 64%, 98%);
$transition: all 0.3s linear;


a,
button {
  cursor: pointer;
  font-family: inherit;
  border: none;
  background: none;
} 

.attribution {
  margin: 2rem auto;
  text-align: center;
  a {
    color: rgb(106, 106, 240);
    text-decoration: underline;
  }
}

.btn {
  border: none;
  height: 40px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: inherit;
}
.container {
 /* max-width: 960px;*/
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.flex-ac {
  display: flex;
  align-items: center;
}


.product {
  margin: 1rem 1rem;
  h2,
  h3 {
    color: black;
    font-family: Kumbh Sans,sans-serif;
  }

  h4 {
    color: var(--color-google-blue);
    margin-bottom: 0.5rem;
    font-size: 0.7rem;
    text-transform: uppercase;
  }
  &--image--container {
    flex: 1;
    flex-direction: column;
    max-width: 360px; 
    position: relative;
    .hide-btn {
      display: none;
    }
    .preview-image{
      justify-content: center;
      align-items: center;
      margin:1rem;
       
    }
    .prod-image{
      width:100%;
      /*max-width: 324px;*/
      object-fit:fill;
    }
    .btn-container {
      position: absolute;
      width: 100%;
      button {
        background:transparent;
        height: 60px;
        width: 60px;
        border-radius: 50px;
        &:hover {
          svg {
            path {
              stroke: var(--color-google-blue);
              transition: all 0.3s linear;
            }
          }
        }
      }
      .right-btn {
        position: absolute;
        right: 1rem;
      }
      width: 100%;
      .left-btn {
        position: absolute;
        left: 1rem;
        background-color: transparent;
      }
      .phone-left-btn {
        position: absolute;
        left: 1rem;
      }
      .phone-right-btn {
        position: absolute;
        right: 1rem;
      }
    }
    img {
      border-radius: 5px;
    }

    .preview-images {
     
      margin-top: 2rem;
        button {
          &:hover {
            opacity: 0.5;
          }
        }
        img{
          max-width:50px;
          max-height: 50px;
        }
      .active-image {
        border: 3px solid var(--color-google-blue);
        border-radius: 8px;
        padding: 0;
        opacity: 0.5;
      }
    }
  }
  &--info {
    margin-top: 2rem;
    margin-left: 4rem;
    margin-bottom: 2rem;
    flex: 0.5;
    li{
      color: #68707d;
    }
    p{
      font-size: 15px;
    color: #68707d;
    line-height: 1.3rem;
    }
    .price {
      background-color: transparent;
      display: flex;
      flex-direction: column;
      h3{
        background-color: transparent;
      }
      h5 {
        color: hsl(220, 14%, 75%);
        text-decoration: line-through;
        font-size: 0.8rem;
        margin-top: 0.3rem;
         background-color: transparent;
      }
    }
    .discount {
      background: hsl(25, 100%, 94%);
      color: var(--color-google-blue);
      padding: 0.1rem 0.3rem;
      border-radius: 4px;
      font-size: 0.7rem;
      margin-left: 0.5rem;
    }
    .counter-container {
      background-color: transparent;
      margin-top: 1rem;
      .counter {
        background: hsl(223, 64%, 98%);
        border-radius: 4px;
        width: 120px;
        justify-content: space-between;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        &--toggle {
          border-radius: 0px;
        }
      }
      .add-btn {
        background: var(--color-google-blue);
        color: white;
        width: 200px;
        margin-left: 0.5rem;
        transition:  all 0.3s linear; 
        &:hover {
          opacity: 0.5;
          box-shadow: 0px 18px 17px 0px hsl(25, 100%, 94%);
        }

        span {
          margin-left: 0.5rem;
        }
      }
    }
  }
}

.light-box {
  background: rgba(0, 0, 0, 0.9);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  /*z-index: 9;*/
  flex-direction: column;
  &--container {
    width:100%;
    position: relative;
    .close-btn {
      position: absolute;
      right: 0;
      justify-content: flex-end;
      align-items: flex-end;
      top: -2.2rem;
      width:50px;
      transform: scale(1.2);
      &:hover {
        svg {
          path {
            fill: #ff7e1b;
            transition:  all 0.3s linear;
          }
        }
      }
    }
  }
}

@include media("<=800px") {
  .product {
    margin-top: 2rem;
  }
}

@include media("<=768px") {
  .light-box--container {
    width: 90%;
  }
  .product-container {
     margin-left: 2rem;
      margin-right: 2rem;
    .product {
      flex-direction: column;
      align-items: unset;
     
      &--image--container {
        flex: 1;
        margin:1rem;
        max-width: 100%;
      }
      &--info {
        flex: 1;
         margin: 1rem; 
         padding-left:1rem;
         padding-right:1rem;
        .price {
          flex-direction: row;
          justify-content: space-between;
        }
      }
      .preview-images:first-of-type {
        display: none;
      }
      .counter-container {
        justify-content: space-between;
        .counter {
          width: 200px;
        }
      }
    }
  }
}

@include media("<=420px") {
  .light-box--container {
    width: 90%;
  }
  .product-container {
    margin-left: 0rem;
    margin-right:1rem;
    .product{
      margin-left:1rem;
      margin-right:1rem;
    }
    .product--info {
      margin: 1rem;
      .counter-container {
        flex-direction: column;
        .counter {
          margin-bottom: 1rem;
        }
        .add-btn {
          margin-left: unset;
        }
        .counter,
        .add-btn {
          width: 100%;
        }
      }
    }
  }
}

@include media("<=360px") {
  .light-box--container {
    width: 70%;
  }
}
