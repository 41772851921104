.everpx__navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 1rem 6rem;
    background-color: transparent;
   /* border-bottom: 1px solid var(--color-grayish-blue); */
}

.everpx__navbar-links {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}


.everpx__navbar-links_logo_container{
   display: flex;
   flex-direction: row;
   justify-content:left;
   align-items: center;
}
.everpx__navbar-links_logo {
    margin-right: 0rem;
}

.everpx__navbar-links_logo img {
    width: 30px;
    height: 30px;
    cursor:pointer;
}

.everpx__navbar-logotext {
    font-family: "Times New Roman", Times, serif;
    font-weight: bold;
    font-size: 30px;
}

.everpx__navbar-links_container {
    display: flex;
    flex-direction: row;
}

.everpx__navbar-sign {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    p {
        color: var(--color-grayish-blue);
        font-family: var(--font-family);
        font-weight: 500;
        font-size: 18px;
        line-height: 25px;
    /* text-transform: capitalize; */
        
        margin: 0 1rem;
        
        cursor: pointer;
    }
}

.everpx__navbar-account-cart {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
 
   .account {
    display:flex;
    margin:2rem;
     position: relative;
   
  }  
}

.everpx__navbar-links_container p,

.everpx__navbar-menu_container p {
    color: var(--color-grayish-blue);
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
   /* text-transform: capitalize; */
     
    margin: 0 1rem;
    
    cursor: pointer;
}


.everpx__navbar-menu_container  {
    z-index:10;
    padding: 0.5rem 1rem;
    color: #fff;
    background: #FF4820; 
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
    width:250px;
}

.signupButton{
    padding: 0.5rem 1rem;
    color: #fff;
    background: #FF4820; 
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
    width:100px;
}

.everpx__navbar-menu {
    margin-left: 1rem;

    display: none;
    position: relative;
}

.everpx__navbar-menu svg {
    cursor: pointer;
}

.everpx__navbar-menu_container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    
    text-align: end;
    background: #fff;
    padding: 2rem;
    position: absolute;
    right: 0;
    top: 40px;
    margin-top: 1rem;
    min-width: 150px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0,0, 0,0.2);
}

.everpx__navbar-menu_container p {
    margin: 1rem 0;
}

.everpx__navbar-menu_container-links-sign {
    display: none;
}

.account-menu{
    border-radius: 0px;
    width: 250px;
}

@media screen and (max-width: 1050px) {
    .everpx__navbar-links_container {
        display: none;
    }

    .everpx__navbar-menu {
        display: flex;
    }
}

@media screen and (max-width: 700px) {
    .everpx__navbar {
        padding: 2rem 4rem;
    }
}

@media screen and (max-width: 550px) {
    .everpx__navbar {
        padding: 2rem;
    }

    .everpx__navbar-sign {
        display: none; 
    }

    .everpx__navbar-menu_container {
        top: 20px;
    }

    .everpx__navbar-menu_container-links-sign {
        display: block;
    }
}