@import 'include-media';

$black: hsl(0, 0%, 0%);
$white: hsl(0, 0%, 100%);
$orange: hsl(26, 100%, 55%);
$pale-orange: hsl(178, 37%, 87%);
$very-dark-blue: hsl(220, 13%, 13%);
$dark-grayish-blue: hsl(219, 9%, 45%);
$grayish-blue: hsl(220, 14%, 75%);
$light-grayish-blue: hsl(223, 64%, 98%);
$transition: all 0.3s linear;


.flex-ac-jb {
  display: flex;
  align-items: center;
  justify-content: space-between; 
 
  
}
.checkout-btn {
      background: var(--color-google-blue);
      color: $white;
      width: 90%;
      margin: 0 auto;
      transition: $transition;
      &:hover {
        opacity: 0.6;
         color: $white;
         background: var(--color-google-blue);
       /* color:var(--color-google-blue);
        box-shadow: 0px 18px 17px 0px $pale-orange;*/
      }
    }

.order-summary-container {
    display:flex;
    margin: 1rem;
    background:transparent;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;

}

.checkout-container2{
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-shadow: 1px 2px 20px 0px lightgrey;
    margin-top:1rem;
    margin-right:3rem;
    padding:2rem;
    width:30%;
    height:100%;

    .term-privacy{
      color: $grayish-blue;
        margin:1rem 0rem;
        border-bottom: 1px solid $grayish-blue;
    }
    .summary-item-container{
        display:flex;
       
        
        width:100%;
        flex-direction: row;
        justify-content: space-between;
        
        
    }
    .summary-total-container{
        border-top:1px solid $grayish-blue;
        display:flex;
        margin: 1rem;
        width:100%;
        flex-direction: row;
        justify-content: space-between;
        font-size: large;
        padding-top:0.5rem;
        color:red
    }
    
    h5{
         
        align-self: flex-start;
    }
}

.cart-container {
    margin: 1rem;
    box-shadow: 1px 2px 20px 0px lightgrey;
    width: 60%;
    padding-bottom: 1rem;
    background: #fff;
   
    
    
    .cart-items-header{
        display:flex;
        border-bottom: 1px solid $grayish-blue;
        justify-content: flex-end;
        margin:0rem 2rem;
    }

    .cart-items-footer{
        display:flex;
        border-top: 1px solid $grayish-blue;
        justify-content: flex-end;
        margin:0rem 2rem;
        margin-bottom: 1rem;
    }
    .cart-items-container{
         margin:0rem 2rem;
    }
    h4 {
      padding-top: 2rem;
       margin:0rem 2rem;
    }
    .cartitem {
      
      
      width:100%;
      &--info {
        margin:0.5rem 0.5rem;
        
      }
      &--info-right{
        display:flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        /*flex-wrap: wrap;*/
      }
      h5 {
        color: $grayish-blue;
        span {
          color: $dark-grayish-blue;
        }
      }
    }
    
  }



@include media("<=700px") {
  
    .order-summary-container{
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin:0rem;
    }
    .checkout-container2{
        width:100%;
         padding-left:4rem;
          padding-right:4rem;
        margin-top:0rem;
        margin-left:2rem;
        margin-right:2rem;
         
    }
    .cart-container{
        width:100%;
        padding-left:2rem;
         padding-right:2rem;
        margin-right:0rem;
        margin-left:0rem;
      .cartitem--info-right{
        display:flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
      }
    }

    
}