.everpx__plans {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    padding: 2rem;
    background: var(--color-bg);

    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    
}
.download-container{
    display:flex;
    flex-direction: column;
}
.download-margin{
    margin-left:6rem;
    margin-right:6rem;
    
    margin-bottom:2rem;
}

.everpx__plans-content {
    display:flex;
    flex-direction: row;
    align-content: space-around;
    margin-left:auto;
    margin-right:auto;
    flex-wrap: wrap;
    
}
.everpx__plans-plan {
    list-style-type: none;
    text-align: center;
    padding: 0;
    margin: 0;
    width:100%;
}

.everpx__plans-black{
    background-color: black;
    color:white;
}
.everpx__plans-blue{
    background-color:var(--color-google-blue);
    color:white;
}

.everpx__plans-light-grey{
    color:#000!important;background-color:#f1f1f1!important
}

.everpx__plans-light-grey{
    color:#000!important;background-color:#f1f1f1!important
}
.everpx__plans-button{
    position:absolute;
    bottom: 0;
    
    border:none;
    display:inline-block;
   
    vertical-align:middle;
    overflow:hidden;
    text-decoration:none;
    color:white;
    background-color:var(--color-google-blue);
    text-align:center;
    cursor:pointer;
    white-space:nowrap;
    border-radius: 0;
    
}
.buy-section{
    background-color: #fff;
    display:flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    margin:0rem;
    padding:0rem;
}
.buy-price{
    padding-top:12px;
}
.buy-button{
    width:100%;
    border:none;
    height:100%;
    vertical-align:bottom;
    overflow:hidden;
    text-decoration:none;
    color:white;
    background-color:var(--color-google-blue);
    text-align:center;
    cursor:pointer;
    white-space:nowrap;
    border-radius: 0;
    margin:0rem;
    
}

.appstore{
    width:150px;
}


.everpx__plans-button:hover{
   /* color:#000!important;
    background-color:var(--color-google-blue)!important;
    color:#fff; */
    opacity: 0.8;
}

.everpx__plans-white{
    color:#000!important;background-color:#fff!important;
}
.everpx__plans-opacity{
    opacity:0.60;
}

.everpx__plans-bottom{
    margin-bottom:16px!important
}

.everpx__plans-half{
    
    background-color: #fff;

    display:flex;
    flex-direction: column;
    justify-content: space-between;
    
    width:260px;
    margin:20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.everpx__plans-floatToBottom{
 
  
}

.everpx__plans-center{
    display:inline-block;
    /*width:auto;*/
}

.everpx__plans-large{
    font-size:24px!important;
}

.everpx__plans-xlarge{
    font-size:30px!important;
}

.everpx__plans-row-padding{
    padding:0 8px;
}

.everpx__plans-col-padding{
    padding-left: 8px;
     padding-right: 8px;
}

.everpx__plans-padding-16{
    padding-top:16px!important;
    padding-bottom:16px!important
}

.everpx_plans_price_frequency{
  font-size:12px;
  color: grey;
}


.everpx__plans-padding-24{
    padding-top:24px!important;
    padding-bottom:24px!important
}
.everpx__plans-padding-32{
    padding-top:32px!important;
    padding-bottom:32px!important
}
.everpx__plans-padding-large{padding:12px 24px!important}

 .everpx__plans-li {
    border-bottom:1px solid #ddd
}



  .everpx__plans-gradient__text {
    background: var(--gradient-text);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }


@media screen and (max-width: 800px) {

.everpx__plans-content {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
   
    width:100%;
    flex-wrap: wrap;
    
}

.download-margin{
    margin-left:4rem;
    margin-right:4rem;
}
    
}


@media screen and (max-width: 500px) {

.download-margin{
    margin-left:2rem;
    margin-right:2rem;
}
}