@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

:root {
  --font-family: 'Manrope', sans-serif;

  --gradient-text: linear-gradient(89.97deg, #AE67FA 1.84%, #F49867 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #AE67FA -13.86%, #F49867 99.55%);
  /* #E2F0A442 */
  --color-bg: #f4f5f042; 
  --color-footer : #031B34;
  --color-blog: #042c54;
  /** #81AFDD */
  --color-text: #4c8bf5;
  /*--color-text: #000509; */
  --color-subtext: #FF8A71;
  --color-google-blue:#4c8bf5;

  --color-success-box-bg:#c6e1c8;
  --color-success-box-text:rgb(43, 43, 57);
  --color-warn-box-bg:#fff;
  --color-warn-box-text:ffa500;
  --color-grayish-blue: hsl(220, 14%, 75%);
  --color-light-grayish-blue: hsl(223, 64%, 98%);
  
}


.flex-ac-jb {
  display: flex;
  align-items: center;
  justify-content: space-between; 
 
  
}

.flex-ac-jc {
  display: flex;
  align-items: center;
  justify-content: center;
}


.flex-as-jc {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
