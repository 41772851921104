.cart {
      margin-right: 1rem;
      position: relative;
      background-color: transparent;
      
    }
 .cart:hover{
  cursor: pointer;
 }   
.cart span {
        color: white;
        position: absolute;
        right: 0.5rem;
        bottom: 1rem;
        left: 1rem;
        z-index: 3;
        background: var(--color-google-blue);
        border-radius: 50px;
        height: 15px;
        width: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
      }    
 
.flex-ac-jc {
  display: flex;
  align-items: center;
  justify-content: center;
  
}

.flex-ac {
  display: flex;
  align-items: center;
   background-color: white;
 
}      