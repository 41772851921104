
.feedback-form {
	padding: 20px;
	margin: 20px;
	/*max-width: 500px; */
	margin:  auto;
	/*background: white;*/
	border-radius: 5px;
    width: 100%;
	/*box-shadow: 10px 10px 0 rgba(0, 0, 0, 0.1); */
}

.feedback-field {
    width:150px;
    border-color: var(--color-google-blue);
}
.feedback-submit-button{
	margin-top:10px;
}