
@import 'include-media';

.your-orders-container{
    display: flex;
   
    flex-direction: column;
    margin:2rem 6rem;
    margin-right:6rem;
}
.width30{
    width: 30%;
}
.width10{
    width: 10%;
}

.width20{
    width: 20%;
}
.order-items-container{
 width:100%;
 
}
.bold{
    font-weight: bold;
}
.order-item-container {
    display: flex;
    
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .order-item-img{
        width:40px;
        height:40px;
        margin-left:1rem;
    }
    .order-item-title-container{
        width:30%;
        display:flex;
        flex-direction: row;
        justify-content: flex-start;
    }
    .order-item-title{
        font-size: small;
    }
    .order-item-date{
       
        font-size: small;
    }
    .order-item-link{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        width:10%;
    }
}

.order-item-container:hover{
   background-color: #F9F9F9;
    cursor: pointer;
}

.order-detail-item-container {
    display: flex;
    
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;

    .order-detail-item-img{
        width:40px;
        height:40px;
        margin-left:1rem;
    }
    .order-detail-item-title-container{
        width:30%;
        display:flex;
        flex-direction: column;
        justify-content: flex-start;
    }
    .order-detail-item-title{
        font-size: small;
    }
    .order-detail-item-amount{
        width:30%;
        display:flex;
        flex-direction: row;
        justify-content: flex-end;
    }
    
    
}


.order-detail-item-container:hover{
   background-color: #F9F9F9;
}


.order-detail-container {
    display:flex;
    flex-direction:column;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: medium;
    
}
.license-item-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.license-item-container:hover{
   background-color: #F9F9F9;
}

.underline{
     border-bottom: 1px solid var(--color-grayish-blue);
}
.flex-row-start{
    display:flex;
    width:50%;
    flex-direction: row;
    justify-content: flex-start;
}
.license-qr-modal-container{
     display:flex;
    flex-direction:column;
    justify-content: flex-start;
    align-items: center;
    font-size: medium;
}
.invoice-pdf{
    background-color: var(--color-grayish-blue);
}
.invoice-download{
    margin:1rem 0rem;
}
.flex-row-sb{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
}
.link{
    color:var(--color-google-blue);
}

@include media("<=600px") {
    .flex-row-start{
        display:none;
    }
}