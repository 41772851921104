.legal{
    padding:2rem 6rem;
    h3{
        font-size:medium;
    }
    h4{
        font-size:medium;
    }
    p{
        line-height:1.8;
    }
}