/* style the container */
.sigin-container {
    width: 100%;
    height: 100%;
    background-color: #f2f2f2;
  } 

.sigin-form {
   position: absolute; /*Can also be `fixed`*/
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        /*Solves a problem in which the content is being cut when the div is smaller than its' wrapper:*/
        max-width: 100%;
        max-height: 100%;
        overflow: auto;


   display: block;  
    margin-left: auto;
    margin-right: auto;
    width: 40%;
    height: 100%;
   
    background-color: #fff;
    padding: 50px 0 30px 0;
  } 

  .success{
      background-color: var(--color-success-box-bg);
      color:var(--color-success-box-text);
    /* background-color: #f2f2f2;  set background color */
    display: flex; /* use flexbox */
    justify-content: center; /* center horizontally */
    align-items: center; /* center vertically */
    border-radius: 5px;
    margin: auto;
  }

  .warn{
    background-color: var(--color-warn-box-bg);
    color:var(--color-warn-box-text);
    border: 1px solid #f00;
     display: flex; /* use flexbox */
    justify-content: center; /* center horizontally */
    align-items: center; /* center vertically */
    border-radius: 5px;
    margin: auto;
  }
  .success p{
    text-align: center; /* center horizontally */
    margin:0;
    padding:10px;
   }
    .warn p{
    text-align: center; /* center horizontally */
    margin:0;
    padding:10px;
   }

   .btn-success {
  background-color: #fff;
  color:var(--color-google-blue);
  border: 1px solid var(--color-google-blue); /* set the border style and color */
  background-color: transparent; /* make the background color transparent */
  padding: 10px 20px; /* add padding to the button */
  }

  .btn-warn {
  background-color: var(--color-google-blue);
  color:#fff;
  padding: 10px 20px; /* add padding to the button */
  }

  .error-message{
    color:red;
    font-size: small;
  }

  .messge-container{
    padding:10px;
  }
  .spacer{
    padding:5px;
  }

  .btn-success:hover {
    border: 1px solid var(--color-google-blue);
    background-color: var(--color-google-blue);
    color:#fff;
  }
  
  /* style inputs and link buttons */
  input,
  .btn {
    width: 100%;
    padding: 12px;
    border: none;
    border-radius: 4px;
    margin: 5px 0;
    opacity: 0.85;
    display: inline-block;
    font-size: 17px;
    line-height: 20px;
    text-decoration: none; /* remove underline from anchors */
  }
  
  input:hover,
  .btn:hover {
    opacity: 1;
  }


  
  span.forgetpass{
   float: right;
   padding-top: 16px;
   padding-right:12px;
  }
  span.signup{
   float: left;
   padding-top: 16px;
   padding-left:12px;
  }
  
  /* style the submit button */
  input[type=submit] {
    background-color: #04AA6D;
    color: white;
    cursor: pointer;
  }
  
  input[type=submit]:hover {
    background-color: #45a049;
  }
  
  .row {
    text-align: center;
    align-items: center;
  }
  .row h2 {
     font-family: "Times New Roman", Times, serif;
     font-size: 35  px;
  }
  
  .row-logo{
    width:100px;
    height:100px;
  }

  .row-logo:hover{
    cursor: pointer;
    
  }

  .fields-container {
    padding: 16px;
  }
  input[type=text], input[type=password] {
  width: 100%;
  padding: 12px 40px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  /*box-sizing: border-box;*/
 }


 button {
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 100%;
  border-radius: 5px;
}

.social-button{
  background-color: #fff;
  color:var(--color-google-blue);
}
.btn-login {
  background-color: var(--color-google-blue);
}

.line-container{
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  color: #ababab;
  padding:1rem 0rem;
}
.line {
  position: relative;
  text-align: center;
  width:45%;
   
}

.line::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  border-color: var(--gradient-bar);
  border-top: 1px solid ;
}

button:hover {
  opacity: 0.8;
}
  /* Clear floats after the columns */
  .row:after {
    content: "";
    display: table;
    clear: both;
  }
  

.horizontalText{
      -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 400;
    list-style: none;
    width: 100%;
    text-align: center;
    background-color: #ffffff;
    position: relative;
    color: #ababab;
    font-size: 14px;
    z-index: 1;
    overflow: hidden;
    padding: 10px 0;
}
.horizontalLine{
      font-size: 75%;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 400;
    list-style: none;
}

  
  /* Responsive layout - when the screen is less than 650px wide, make the two columns stack on top of each other instead of next to each other */
  @media screen and (max-width: 650px) {
    .sigin-form{
      width:100%;
    }
    
  }