.faq-item-container{
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width:100%;
}
.faq-item-question-container{
     margin-top:1rem;
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start ;
    
}
.faq-item-question-icon{
  width:20px;
  cursor: pointer;
}

.faq-item-question{
   margin-left:1rem;
   font-size: large;
   cursor: pointer;
   
}
.faq-item-answer-container{
   /* margin-left:2rem; */
   /* margin-right:6rem; */
    /*background-color: #F9F9F9; */
    background-color: #DAF5FF;
    display:flex;
    flex-direction: flex-start;
    width:100%;
    padding: 1rem;
}

.underline{
    width:100%;
    border-bottom: 1px solid var(--color-grayish-blue);
}

.faq-container{
     display:flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin:1rem 6rem;
}