@import 'include-media';

.your-account-container{
    display: flex;
    flex-direction: column;
    margin:2rem 25rem;
}

a{
    color:var(--color-google-blue);
}
.underline{
     border-bottom: 1px solid var(--color-grayish-blue);
}
select{
    width:100%;
    height:40px;
    border-color: var(--color-grayish-blue);
    border-radius: 5px;
}
textarea{
    width:100%;
    padding:1rem;
    border-color: var(--color-grayish-blue);
    border-radius: 5px;
}
.btn-save{
    background-color: var(--color-google-blue);
    width:150px;
}
.flex-col-start{
    display:flex;
    flex-direction: column;
    align-items: flex-start;
}
.fieldLabel{
    margin-top:0rem;
    margin-bottom: 2px;
    font-weight: bold;
    text-transform: capitalize;
}
.flex-row-sb{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.account-item-container{
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.width20{
    width: 20%;
}
.width80{
    width:80%;
}
.margin-right{
 margin-right: 1rem;
}

.margin-top{
 margin-top: 1rem;
}
.account-item{
   
   margin-top:1rem ;
   display:flex;
   flex-direction: row;
   justify-content: flex-start;
   align-items: flex-start;

   &-title{
    margin-right:1rem;
    
   }
   &-value{
    font-weight: bold;
   
   }
}
.link{
    color:var(--color-google-blue);
}



@include media("<=1200px") {
    .your-account-container{
        margin:2rem 10rem;
    }
}

@include media("<=800px") {
    .your-account-container{
        margin:2rem 6rem;
    }
}


@include media("<=600px") {
    .your-account-container{
        margin:2rem 2rem;
    }
}